<template>
  <Tv v-if="!isMobile" />
  <TvMobile v-if="isMobile" />
</template>

<script>
// import Words from './components/Words.vue'
// import WordsMobile from './components/Words-mobile.vue'
import Tv from './components/Tv.vue'
import TvMobile from './components/Tv-mobile.vue'

export default {
  name: 'App',
  components: {
    // Words,
    // WordsMobile,
    Tv,
    TvMobile
  },
  data() {
    return {
      isMobile: false
    }
  },
  created () {
    this.isMobile = window.innerWidth <= 768
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768
    })
  },
}

</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #646464;
  height: 100vh;
  box-shadow: inset 0 0 1200px black;
}
</style>
