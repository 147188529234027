<template>
  <section class="home">
    <div class="tv_wrapper">
      <img class="tv" src="../assets/tv.svg" />
      <div class="screen" tabindex="0" ref="screen">
        <img v-if="channel === 1" class="video" src="../assets/laketimelapse.gif" />
        <img v-if="channel === 2" class="test-card" src="../assets/test-card.jpeg" />
        <img v-if="channel === 3" class="stars" src="../assets/stars.gif" />
      </div>
      <div class="dials">
        <a v-on:click="changeChannel()" id="dial1" title="Change the Channel"><img class="dial"  src="../assets/tv-dial-white.svg" ref="dial1" /></a>
        <a  id="dial2" href="https://www.linkedin.com/in/michael-fresson-444508158/" title="LinkedIn" target="_blank"><img class="dial" src="../assets/tv-dial-white.svg" ref="dial2" /></a>
      </div>
      <div
        class="text">
        <h1>Michael Fresson</h1>
        <h2>Full-Stack Developer</h2>
      </div>
    </div>
  </section>
</template>

<script>
import { TimelineMax, gsap, Power1 } from 'gsap';
gsap.registerPlugin()

export default {
  name: 'Home',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      channel: 1
    }
  },
  methods: {
    changeChannel() {
      if (this.channel > 3) {
        return this.channel = 1;
      }
      return this.channel++;
    },
    setupAnimation() {
      const tl = new TimelineMax();
      const { dial1, dial2 } = this.$refs;

      tl.addLabel('start')
        .to([dial1, dial2], 5, { rotate: 1080, ease: Power1.easeInOut }, 0)

    }
   
  },
  mounted() {
    this.setupAnimation()
  }
}
</script>

<style scoped>

/* TV Frame Stylings */

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tv_wrapper {
  position: fixed;
  width: auto;
  top: 40px;  
}

.tv {
  position: relative;
  height: 200px;
  z-index: 2;
  object-fit: contain;
}

/* Screen Stylings */

.screen {
  position: absolute;
  display: block;
  overflow: hidden;
  top: 40px;
  left: 5px;
  height: 150px;
  width: 200px;
  z-index: 1;
  object-fit: fill;
  background-color: rgb(15, 15, 15);
}

.video, .test-card, .stars {
  width: -webkit-fill-available;
}

.video {
  height: 150px;
}

/* Text Stylings */

.text {
  position: absolute;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 auto;
  z-index: 5;
  top: 300px;  
  text-align: center;
  transition: all 1s;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 26px;
  margin-top: 22px;
}

/* Dial Stylings */

a, .dial {
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 4;
  transition: 1s;
}

#dial1 {
  top: 213px;
  left: 36px;
}

#dial2 {
  top: 213px;
  left: 131px;
  transform: rotate(45deg);
}

.dial:hover, a:focus {
  transform: rotate(900deg) !important;

}

</style>
