<template>
  <section class="tv_section">
    <div class="tv_wrapper">
      <img class="tv" src="../assets/tv.svg" />
      <transition name="fade">
      <span v-if="showGraffiti" id="graffiti">for a good time,<br>play with knobs.</span>
      </transition>
      <div class="screen" tabindex="0" ref="screen">
        <video v-if="channel === 1" class="video" ref="vid" tabindex="0" autobuffer="autobuffer" preload="preload" autoplay muted loop>
          <source src="../assets/laketimelapse.mp4" type="video/mp4" >
        </video>
        <img v-if="channel === 2" class="test-card" src="../assets/test-card.jpeg" />
        <img v-if="channel === 3" class="stars" src="../assets/stars.gif" />
        <transition name="fade">
          <div 
            v-if="channel > 2"
            class="text">
            <h1>Michael Fresson</h1>
            <h2>Full-Stack Developer</h2>
          </div>
        </transition>
      </div>
      <a v-on:click="changeChannel()" id="dial1" title="Change the Channel"><img class="dial"  src="../assets/tv-dial.svg" /></a>
      <a id="dial2" href="https://mikefress.github.io/snek-game/" title="Play A Game?" target="_blank"><img class="dial" src="../assets/tv-dial.svg" /></a>
      <a  id="dial3" href="https://www.linkedin.com/in/michael-fresson-444508158/" title="LinkedIn" target="_blank"><img class="dial" src="../assets/tv-dial.svg" /></a>
    </div>
  </section>
</template>

<script>
// import { TimelineMax, gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Home',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      channel: 1,
      showGraffiti: false,
    }
  },
  computed: {
  },
  methods: {
    changeChannel() {
      if (this.channel > 3) {
        return this.channel = 1;
      }
      return this.channel++;
    },
    toggleShowGraffiti() {
      this.showGraffiti = true;

        this.timerId = setInterval(() => {
          this.remainingTimeoutSeconds -= 1000;
      }, 1000);
    },
  },
  mounted() {
    setTimeout(() => { this.showGraffiti = true; }, 6000);
  }
}
</script>

<style scoped>

/* TV Frame Stylings */

.tv_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tv_wrapper {
  position: fixed;
  width: auto;
  top: 40px;  
  overflow: hidden;
}

.tv {
  position: relative;
  height: 750px;
  z-index: 2;
  object-fit: contain;
}

#graffiti {
  position: absolute;
  top: 162px;
  left: 664px;
  transform: rotate(-13deg);
  font-family: 'Indie Flower', cursive;
  z-index: 3;
}

/* Screen Stylings */

.screen {
  display: block;
  overflow: hidden;
  top: 193px;
  left: 45px;
  height: 513px;
  width: 660px;
  position: absolute;
  z-index: 1;
  object-fit: fill;
  background-color: rgb(15, 15, 15);
}

.video, .test-card, .stars {
  width: -webkit-fill-available;
}

.text {
  position: absolute;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 auto;
  z-index: 5;
  top: 160px;  
  left: 119px;
  text-align: center;
  transition: all 1s;
}

h1 {
  font-size: 56px;
}

h2 {
  font-size: 36px;
  margin-top: 82px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Dial Stylings */

a, .dial {
  position: absolute;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 4;
  transition: 1s;
}

#dial1 {
  top: 213px;
  left: 716px;
}

#dial2 {
  top: 305px;
  left: 716px;
  transform: rotate(45deg);
}

#dial3 {
  top: 396px;
  left: 716px;
  transform: rotate(120deg);
}

.dial:hover, a:focus {
  transform: rotate(90deg) !important;

}

</style>
